import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import AccordionSection from './accordion-section';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Accordion = (props) => {
  const breakpoints = useBreakpoint();
  const [allowMultiple, setAllowMultiple] = useState(false);
  const [openSections, setOpenSections] = useState({});

  useEffect(() => {
    setAllowMultiple(props.allowMultiple);
  }, [props.allowMultiple]);

  const onSectionClick = sectionId => {
    const isOpen = !!openSections[sectionId];

    if (allowMultiple) {
      setOpenSections({ ...openSections, [sectionId]: !isOpen });
    } else {
      setOpenSections({ [sectionId]: !isOpen });
    }
  };

  const sectionStyle = breakpoints.mobile ? props.mobileStyles : props.desktopStyles;

  return (
    <div className={styles.container}>
        {React.Children.toArray(props.children).map((child, index) => (
          <div className={styles.section} key={`${index}-${child.props.label}`} style={{ ...sectionStyle }}>
            <AccordionSection
              label={child.props.label}
              isOpen={id => !!openSections[id]}
              onClick={onSectionClick}
            >
              {child.props.children}
            </AccordionSection>
          </div>
        ))}
    </div>
  );
};

export default Accordion;

Accordion.defaultProps = {
  desktopStyles: {
    marginBottom: 3,
  },
  mobileStyles: {
    marginBottom: 3,
  },
  allowMultiple: false,
};

Accordion.propTypes = {
  desktopStyles: PropTypes.object,
  mobileStyles: PropTypes.object,
  allowMultiple: PropTypes.bool,
  children: PropTypes.node,
};
