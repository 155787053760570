import React from 'react';
import PropTypes from 'prop-types';
import styles from './spacing.module.scss';

const Spacing = (props) => {
  return (
    <div className={styles.container}>{props.children}</div>
  );
};

export default Spacing;

Spacing.propTypes = {
  children: PropTypes.node,
};
