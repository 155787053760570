import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import Spacing from '@components/ui/spacing';
import styles from './index.module.scss';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const getCustomStyle = breakpoints => {
  if (breakpoints.mobile || breakpoints.tablet) {
    return { minHeight: '500px',  maxHeight: '500px' };
  }
  return { minHeight: '550px', height: '100vh', width: '100vw' };
};

const Hero = (props) => {
  const breakpoints = useBreakpoint();
  const customStyle = getCustomStyle(breakpoints);
  
  if (props.heroImage) {
    return (
      <BackgroundImage fadeIn={false} fluid={props.heroImage} style={{ ...customStyle, maxWidth: '100%' }}>
        <div className={styles.bannerContent} style={{ ...customStyle, width: '100%', height: '100%' }} >
          <Spacing>
            {props.children}
          </Spacing>
        </div>
      </BackgroundImage>
    );
  }

  return null;
};

export default Hero;

Hero.defaultProps = {
  heroImage: null,
  backgroundStyle: {},
};

Hero.propTypes = {
  heroImage: PropTypes.object,
  children: PropTypes.node,
  backgroundStyle: PropTypes.object,
};
