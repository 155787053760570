import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import EmptySpace from "@components/ui/empty-space";
import Hero from "@components/ui/hero";
import Layout from "@components/domain/shared/layout";
import Spacing from "@components/ui/spacing";
import Title from "@components/ui/title";
import SEO from "@components/ui/seo";
import Accordion from "@components/ui/accordion";
import styles from "@styles/pages/perguntas-frequentes.module.scss";

const faq = [
  {
    title: "Quanto se paga para participar no After School?",
    value:
      "Os cursos After School seguem o modelo 'pay what you want'. Isto significa que cada um paga aquilo que achar justo pela sua participação no After School. O After School organizado pelo TreeTree2, uma associação sem fins lucrativos que visa promover o ensino extracurricular de excelência nas áreas de Ciência e Engenharia, e consideramos que as questões financeiras não devem ser um impedimento à participação de qualquer aluno. Como tal, deixamos que sejam os encarregados de educação a definir o valor do seu apoio ao After School. Qualquer valor doado ao TreeTree2 será investido na expansão das suas atividades. Assim, ao apoiar o After School, não só está a ajudar-nos a chegar a mais alunos e a criar mais cursos, como também está a apoiar outras atividades que temos, em formatos diferentes do After School, tais como a Academia de Verão e o HAC de Aprendizagem Científica - e muitos outros programas ainda na gaveta!",
  },
  {
    title: "Gostava de participar em mais do que um curso. É possível?",
    value:
      "Recomendamos candidatares-te a todos os cursos em que estejas interessado e para o qual tenhas disponibilidade de horário. No entanto, dado o número de vagas para cada curso ser bastante menor do que o respetivo número de candidaturas, será pouco provável um aluno participar em dois cursos em simultâneo de forma a dar oportunidade a um maior número de alunos.",
  },
  {
    title:
      "O meu ano de escolaridade não aparece em nenhum os cursos After School. Isso quer dizer que o After School não é para mim?",
    value:
      "Não! Mesmo que os cursos que estejam abertos não se enquadrem no teu ano de escolaridade, isso não quer dizer que o After School não é para ti. Nós pretendemos alargar o After School a todos os anos de escolaridade. Por isso, fica atento! É bem possível que, brevemente, tenhas cursos para os teus anos de escolaridade.",
  },
  {
    title: "O que é o regime de 'aula invertida'?",
    value:
      "O regime de aula invertida é um regime que... inverte a lógica de ensino habitual. Assim, neste regime, recorre-se a uma abordagem pedagógica caracterizada por dois aspetos: 1) Em casa, os alunos aprendem os conteúdos através de recursos digitais, tais como vídeos, textos, plataformas interativas e outros; e 2) Na aula, os alunos fazem exercícios, atividades de grupo e esclarecem dúvidas com a equipa de formação. Para além de ser um regime de ensino que fomenta a autonomia, a aula invertida tem demonstrado ter um impacto positivo no sucesso escolar dos alunos.",
  },
];

const Privacy = () => {
  const data = useStaticQuery(graphql`
    query FAQBackgroundImageQuery {
      backgroundImage: file(name: { eq: "homepage-hero" }) {
        name
        internal {
          mediaType
        }
        childImageSharp {
          fixed(width: 1260) {
            height
            width
            src
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Hero />
      <SEO
        title="Perguntas frequentes"
        description="afterschool. Cursos extracurriculares de excelência em ciência, tecnologia, engenharia e matemática."
        image={{
          src: data.backgroundImage.childImageSharp.fixed.src,
          height: data.backgroundImage.childImageSharp.fixed.height,
          width: data.backgroundImage.childImageSharp.fixed.width,
          name: data.backgroundImage.name,
          mediaType: data.backgroundImage.internal.mediaType,
        }}
      />

      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
      <div className={styles.container}>
        <Spacing>
          <Title value="Perguntas frequentes" maxWidth="300px" />
        </Spacing>

        <section className={styles.faqSection}>
          {faq.length && (
            <Accordion
              desktopStyles={{ marginBottom: 30 }}
              mobileStyles={{ marginBottom: 20 }}
              allowMultiple={true}
            >
              {faq.map((item, index) => (
                <div key={`${index}-${item.title}`} label={item.title}>
                  <p className={styles.answerSection}>{item.value}</p>
                </div>
              ))}
            </Accordion>
          )}
        </section>

        <EmptySpace
          desktop={{ margin: 180, isLineVisible: false }}
          mobile={{ margin: 80, isLineVisible: false }}
        />
      </div>
    </Layout>
  );
};

export default Privacy;
