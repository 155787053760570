import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { v4 as uuidv4 } from 'uuid';
import "@fortawesome/fontawesome-svg-core/styles.css";
import styles from './accordion-section.module.scss';
config.autoAddCss = false;

const AccordionSection = (props) => {
  const breakpoints = useBreakpoint();
  const [sectionId] = React.useState(uuidv4());

  const headerMargin = breakpoints.tablet ? "15px" : "35px";
  const isSectionOpen = props.isOpen(sectionId);

  const handleOnClick = () => props.onClick(sectionId);
  const handleKeyDown = event => event.key === 'Enter' && props.onClick(sectionId);

  return (
    <div
      aria-expanded={isSectionOpen}
      className={`${styles.container} ${isSectionOpen ? styles.isOpen : ''}`}
      tabIndex="-1"
    >

      <div
        className={styles.header}
        onClick={handleOnClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
        style={{
          margin: `0 ${headerMargin}`,
        }}
      >
        <h3 aria-labelledby={props.label} tabIndex="-1">{props.label}</h3>
        <button tabIndex="-1" type="button">
          <FontAwesomeIcon icon={isSectionOpen ? faMinus : faPlus} color="#919191" size="1x" />
        </button>
      </div>

      <div className={styles.content} tabIndex="-1">
        {props.children}
      </div>

    </div>
  );
};

export default AccordionSection;

AccordionSection.defaultProps = {
  onClick: () => {},
  label: '',
};

AccordionSection.propTypes = {
  isOpen: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.node,
};
