import React from 'react';
import styles from './title.module.scss';
import PropTypes from 'prop-types';

const Title = (props) => {
  return (
    <h1 className={styles.content} style={{ color: props.color, maxWidth: props.maxWidth }}>
      {props.value}
    </h1>
  );
};

export default Title;

Title.defaultProps = {
  maxWidth: 'none',
  value: '',
  color: 'black',
};

Title.propTypes = {
  value: PropTypes.string,
  color: PropTypes.string,
  maxWidth: PropTypes.string,
};
